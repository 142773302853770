import { DefaultSeo } from 'next-seo';
import { NextWebVitalsMetric } from 'next/app';
import { GlobalStyle } from '@styles/global-styles';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ResponsiveGlobal } from '@styles/responsive';
import React, { useEffect } from 'react';
import { NextComponentType } from 'next';
import Script from 'next/script';
import { onDisabledReactDevtoolOnProduction } from '@utils/helper';

// import '../../scripts/wdyr.js';

import { wrapper } from '../store';

interface AppProps {
  Component: NextComponentType<any>;
  pageProps: any;
}

const MyApp: React.FunctionComponent<AppProps> = ({
  Component,
  pageProps,
}: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    onDisabledReactDevtoolOnProduction();
  }, []);

  return (
    <>
      <Head>
        <meta
          id="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
      </Head>
      <DefaultSeo
        title="Panoee | The Smart Virtual Tour Software for everyone"
        description="Start free to build high-quanlity & smart 360° Virtual Tour in minutes with beautiful themes & unlimited customization."
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: router.pathname,
          site_name: 'Panoee',
          title: 'Panoee | The Smart Virtual Tour Software for everyone',
          description:
            'Start free to build high-quanlity & smart 360° Virtual Tour in minutes with beautiful themes & unlimited customization.',
        }}
        additionalMetaTags={[
          {
            property: 'category',
            content: 'Virtual Tour Software',
          },
          {
            property: 'name',
            content: 'Panoee Studio',
          },
          {
            name: 'application-name',
            content: 'Panoee',
          },
        ]}
      />
      <Component {...pageProps} />
      <GlobalStyle />
      <ResponsiveGlobal />

      <Script
        strategy="beforeInteractive"
        src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/data.js`}
      />

      <Script
        strategy="beforeInteractive"
        src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/core/tour.js`}
      />
    </>
  );
};

export function reportWebVitals({
  id,
  name,
  // startTime,
  value,
  label,
}: NextWebVitalsMetric) {
  if ((window as any).gtag)
    (window as any).gtag('send', 'event', {
      eventCategory:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value / 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
}

export default wrapper.withRedux(MyApp);
