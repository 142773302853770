import { DEFAULT_VALUE_COLLABORATION_CONFIG } from './constants';
import { I_Group, I_Scene, I_Plugin, I_FormCaptureConfig } from './types';

// NOTE: 8 plugins CURRENTLY

export const analyticsConfigPlugin = (plugins: I_Plugin[]) => {
  const pluginMap = plugins.filter(item => item.title === 'map')[0];
  const pluginLogo = plugins.filter(item => item.title === 'logo')[0];
  const pluginNadir = plugins.filter(item => item.title === 'nadir')[0];
  const pluginCallToAction = plugins.filter(
    item => item.title === 'callToAction',
  )[0];
  const pluginPopupIntro = plugins.filter(
    item => item.title === 'popupIntro',
  )[0];
  const pluginFloorplan = plugins.filter(item => item.title === 'floorplan')[0];
  const pluginFormCapture = plugins.filter(
    item => item.title === 'formCapture',
  )[0];
  const pluginProtectPassword = plugins.filter(
    item => item.title === 'protectPassword',
  )[0];
  const pluginCopyRight = plugins.filter(item => item.title === 'copyRight')[0];
  const pluginCollaboration = plugins.filter(
    item => item.title === 'collaboration',
  )[0];

  const dataMap =
    pluginMap?.title === 'map' ? pluginMap?.config?.data || null : null;

  const configMap =
    pluginMap?.title === 'map' ? pluginMap?.config?.config || null : null;

  const configLogo =
    pluginLogo?.title === 'logo' ? pluginLogo?.config?.config || null : null;

  const configNadir =
    pluginNadir?.title === 'nadir' ? pluginNadir?.config?.config || null : null;

  const configPopupIntro =
    pluginPopupIntro?.title === 'popupIntro'
      ? pluginPopupIntro?.config?.config || null
      : null;

  const configProtectPassword =
    pluginProtectPassword?.title === 'protectPassword'
      ? pluginProtectPassword?.config?.config || null
      : null;

  const configCopyRight =
    pluginCopyRight?.title === 'copyRight'
      ? pluginCopyRight?.config?.config || null
      : null;

  const listFloorplan =
    pluginFloorplan?.title === 'floorplan'
      ? pluginFloorplan?.config?.data?.filter(
          item => item.name && item.src && item.publish,
        ) || []
      : [];

  const configFloorplan =
    pluginFloorplan?.title === 'floorplan'
      ? pluginFloorplan?.config?.config || null
      : null;

  const listFormCapture =
    pluginFormCapture?.title === 'formCapture'
      ? pluginFormCapture?.config?.data?.filter(item => item.publish) || []
      : [];

  const configFormCapture =
    pluginFormCapture?.title === 'formCapture'
      ? pluginFormCapture?.config?.config || []
      : [];

  const formCaptureConfigOnProject = (() => {
    const data = configFormCapture?.filter(
      item => item.type === 'project' && item.form,
    );
    return data.length ? data[0] : undefined;
  })();

  const listCallToAction =
    pluginCallToAction?.title === 'callToAction'
      ? pluginCallToAction?.config?.data || []
      : [];

  const configCollaboration =
    pluginCollaboration?.title === 'collaboration'
      ? pluginCollaboration?.config?.config ||
        DEFAULT_VALUE_COLLABORATION_CONFIG
      : DEFAULT_VALUE_COLLABORATION_CONFIG;

  return {
    dataMap,
    configMap,

    configLogo,

    configNadir,

    configPopupIntro,

    configProtectPassword,

    configCopyRight,

    configCollaboration,

    listFloorplan,
    configFloorplan,

    listFormCapture,
    configFormCapture,
    formCaptureConfigOnProject,

    listCallToAction,
  };
};

export const analyticsListSceneAndListGroup = (
  listSceneBefore: I_Scene[],
  groups: I_Group[],
) => {
  const scenes =
    listSceneBefore
      .filter(el => !el.parent)
      .map(item => ({
        ...item,
        staging:
          listSceneBefore
            ?.filter(el => el.parent && el.parent === item.id)
            // Because the array is frozen in strict mode, you'll need to copy the array before sorting it: (https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326)
            ?.filter(el => el.mode_in_parent === 'staging')
            ?.slice()
            ?.sort((a, b) =>
              a.sort_in_parent === b.sort_in_parent
                ? Math.abs(
                    new Date(a.updated_at).valueOf() -
                      new Date(b.updated_at).valueOf(),
                  )
                : a.sort_in_parent - b.sort_in_parent,
            ) || [],
        day2dust:
          listSceneBefore
            ?.filter(el => el.parent && el.parent === item.id)
            ?.filter(el => el.mode_in_parent === 'day2dust') || [],
      })) || [];

  const listScene =
    scenes.sort((a, b) =>
      a.sort === b.sort
        ? Math.abs(
            new Date(a.updated_at).valueOf() - new Date(b.updated_at).valueOf(),
          )
        : a.sort - b.sort,
    ) || [];

  const groupUncategory: I_Group = {
    id: 'uncategory',
    name: 'Uncategory',
    sort: 0,
    scenes: scenes
      .filter(item => !item.group)
      .filter(item => item.publish)
      .sort((a, b) =>
        a.sort === b.sort
          ? Math.abs(
              new Date(a.updated_at).valueOf() -
                new Date(b.updated_at).valueOf(),
            )
          : a.sort - b.sort,
      ),
  };

  const listGroup = [groupUncategory]
    .concat(
      groups.map(item => ({
        ...item,
        scenes:
          scenes
            .filter(scene => scene.group === item.id)
            .filter(scene => scene.publish)
            .sort((a, b) =>
              a.sort === b.sort
                ? Math.abs(
                    new Date(a.updated_at).valueOf() -
                      new Date(b.updated_at).valueOf(),
                  )
                : a.sort - b.sort,
            ) || [],
      })),
    )
    .filter(item => item.scenes.length);

  return { listGroup, listScene };
};

export const onReturnListHotspotIdToOpenFormCapture = (
  listScene: I_Scene[],
  config: I_FormCaptureConfig[],
) =>
  (
    config
      ?.filter(
        item => item.type === 'hotspot' && item.by === 'id' && item.hotspot_id,
      )
      .map(item =>
        item.type === 'hotspot' &&
        item.by === 'id' &&
        item.hotspot_id &&
        item.form
          ? item.hotspot_id
          : '',
      ) || []
  ).concat(
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'scene' &&
          item.hotspot_scene_id &&
          item.form,
      )
      .map(item =>
        item.type === 'hotspot' && item.by === 'scene' && item.hotspot_scene_id
          ? item.hotspot_scene_id
          : '',
      )
      .map(item => listScene.filter(scene => scene.id === item)[0] || undefined)
      .filter(item => item)
      .reduce((result, item) => {
        result = result.concat(item.hotspots.map(hotspot => hotspot.id));
        return result;
      }, []),
  );

export const onReturnListFormCaptureConfigCorrespondingToListHotspotId = (
  listScene: I_Scene[],
  config: I_FormCaptureConfig[],
) =>
  (
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'id' &&
          item.hotspot_id &&
          item.form,
      )
      .map(item =>
        item.type === 'hotspot' && item.by === 'id' && item.hotspot_id
          ? item
          : null,
      ) || []
  ).concat(
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'scene' &&
          item.hotspot_scene_id &&
          item.form,
      )
      .reduce((result, item) => {
        if (item.type === 'hotspot' && item.by === 'scene') {
          const scene =
            listScene.filter(el => el.id === item.hotspot_scene_id)[0] ||
            undefined;
          if (scene) result = result.concat(scene.hotspots.map(() => item));
        }
        return result;
      }, [])
      .map(item => item),
  );

export const onReturnListHotspotTypeToOpenFormCapture = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(
      item =>
        item.type === 'hotspot' &&
        item.by === 'type' &&
        item.hotspot_type &&
        item.form,
    )
    .map(item =>
      item.type === 'hotspot' && item.by === 'type' && item.hotspot_type
        ? item.hotspot_type
        : 'point',
    ) || [];

export const onReturnListFormCaptureConfigCorrespondingToListHotspotType = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(
      item =>
        item.type === 'hotspot' &&
        item.by === 'type' &&
        item.hotspot_type &&
        item.form,
    )
    .map(item =>
      item.type === 'hotspot' && item.by === 'type' && item.hotspot_type
        ? item
        : null,
    )
    .map(item => item) || [];

export const onReturnListSceneIdToOpenFormCapture = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(item => item.type === 'scene' && item.scene_id && item.form)
    .map(item =>
      item.type === 'scene' && item.scene_id ? item.scene_id : '',
    ) || [];

export const onReturnListFormCaptureConfigCorrespondingToListSceneId = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(item => item.type === 'scene' && item.scene_id && item.form)
    .map(item => (item.type === 'scene' && item.scene_id ? item : null))
    .map(item => item) || [];
